angular.module('tgaAdmin')
    .service('flashService', ['$rootScope',
        function ($rootScope) {
			var queue = [],
				current = [];

			return {
				set: function (message, type) {
					queue.push({
						'message': message,
						'type': type
					});
				},
				get: function () {
					if (queue.length > 0) {
						current = queue;
						queue = [];
					} else {
						current = [];
					}

					return current;
				}
			};
		}
	]);