angular.module('tgaAdmin')
    .service('playerService', ['$http', 'envService', 'localStorageService',
        function ($http, envService, localStorageService) {

            var serverAddress = envService.read('serverAddress');

            return ({
                getPlayer: getPlayer,
                getPlayers: getPlayers,
                getTotalPlayers: getTotalPlayers,
                getNewPlayers: getNewPlayers,
            });

            function getPlayer(userId) {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'stats/player/' + userId,
					params: {
						game_id: localStorageService.get('game_id')
					}
                }).then(
                    function (response) {
                        return response.data;
                    },
                    function (error) {
                        throw error.data;
                    }
                );
			}

			function getPlayers(startDate, endDate) {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'stats/player',
					params: {
						game_id: localStorageService.get('game_id'),
						start_date: startDate,
						end_date: endDate
					}
                }).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
                );
			}

			function getTotalPlayers(startDate, endDate) {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'stats/player/total',
					params: {
						game_id: localStorageService.get('game_id'),
						start_date: startDate,
						end_date: endDate
					}
                }).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
                );
			}

			function getNewPlayers(startDate, endDate) {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'stats/player/new',
					params: {
						game_id: localStorageService.get('game_id'),
						user_type: 'player',
						start_date: startDate,
						end_date: endDate
					}
                }).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
                );
			}

        }]);