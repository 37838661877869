angular.module('tgaAdmin')
    .service('authService', ['localStorageService',
        function (localStorageService) {

            var storageKey = 'authentication';

            var authentication = {
                isAuth: false,
                token: '',
                userId: ''
            };

            return ({
                getAuthentication: getAuthentication,
                setAuthentication: setAuthentication,
                clearAuthentication: clearAuthentication
            });

            function getAuthentication() {
                // Check cache
                if (authentication && authentication.isAuth) {
                    return authentication;
                }

                authentication = localStorageService.get(storageKey);

				if (!authentication) {
					localStorageService.setStorageType('sessionStorage');
					authentication = localStorageService.get(storageKey);
				}

                if (!authentication || !authentication.isAuth) {
                    clearAuthentication();
                }

                return authentication;
            }

            function setAuthentication(auth, remember) {
                authentication = auth;

				if(remember) {
					localStorageService.setStorageType('localStorage');
				} else {
					localStorageService.setStorageType('sessionStorage');
				}

                localStorageService.set(storageKey, authentication);
            }

            function clearAuthentication() {
                authentication = {
                    isAuth: false,
                    token: '',
                    userId: ''
                };
                localStorageService.remove(storageKey);
                return authentication;
            }
        }]);