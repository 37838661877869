angular.module('tgaAdmin')
    .controller('HomeController', ['$scope', '$state', '$timeout', 'gameService', 'playerService', 'statsService', '$filter', '$uibModal', 'localStorageService', 'modalService',
        function ($scope, $state, $timeout, gameService, playerService, statsService, $filter, $uibModal, localStorageService, modalService) {

            $scope.data = {
                alerts: [],
				filedate: $filter('date')(new Date(), 'yyyy-MM-dd hh-mm-ss'),
				separator: ',',
				decimalSeparator: '.',
				predicates: [{
					name: 'Name',
					value: 'display_name'
				}, {
					name: 'E-Mail',
					value: 'email'
				}],
				defaultPredicates: [{
					name: 'Name',
					value: 'display_name'
				}, {
					name: 'E-Mail',
					value: 'email'
				}],
				datePickerOptions: {
					clearable: true,
					opens: 'left',
					alwaysShowCalendars: true,
					showCustomRangeLabel: false,
					maxDate: moment().endOf('day'),
					locale: {
						format: 'M/D/YYYY',
						cancelLabel: 'Clear',
					},
					ranges: {
						'Today': [moment(), moment()],
						'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
						'Last 7 Days': [moment().subtract(6, 'days'), moment()],
						'Last 30 Days': [moment().subtract(29, 'days'), moment()],
						'This Month': [moment().startOf('month'), moment().endOf('month')],
						'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
					},
					eventHandlers: {
						'apply.daterangepicker': function(ev, picker) {
							if($(picker.element[0]).hasClass('player-dates')) {
								$scope.viewmodel.playerDates = {
									startDate: picker.startDate,
									endDate: picker.endDate
								};
								$scope.actions.loadUsers();
							}

							if($(picker.element[0]).hasClass('question-dates')) {
								$scope.viewmodel.questionDates = {
									startDate: picker.startDate,
									endDate: picker.endDate
								};
								$scope.actions.loadQuestions();
							}
						},
						'cancel.daterangepicker': function(ev, picker) {
							if($(picker.element[0]).hasClass('player-dates')) {
								$scope.viewmodel.playerDates = {
									startDate: null,
									endDate: null
								};
								$scope.actions.loadUsers();
							}

							if($(picker.element[0]).hasClass('question-dates')) {
								$scope.viewmodel.questionDates = {
									startDate: null,
									endDate: null
								};
								$scope.actions.loadQuestions();
							}
						}
					}
				}
            };

			$scope.selectedPredicate = $scope.data.predicates[0].value;

            $scope.viewmodel = {
                totalPlayers: 0,
                newPlayers: 0,
				players: [],
				highScore: 0,
				totalSessions: 0,
				timeMin: 0,
				timeSec: 0,
				questions: [],
				playerDates: {
					startDate: null,
					endDate: null
				},
				questionDates: {
					startDate: null,
					endDate: null
				},
            };

            $scope.initialize = function () {
				gameService.getGames().then(function(games) {
					if(!games.length) {
						$timeout(function() {
							modalService.showModal({
								title: 'Welcome',
								message: 'You do not have any games. Add your first game now.',
								closeButton: false,
								actionButton: 'OK'
							}).then(function() {
								$state.go('addGame');
							});
						}, 0);
					} else {
						// Set global game_id
						var game_id = localStorageService.get('game_id') || games[0].game_id;
						var game = $filter('filter')(games, {game_id: game_id}, true)[0];
						localStorageService.set('game_id', game_id);
						$scope.actions.loadUsers();
						$scope.actions.loadQuestions();
					}
				});
            };

			$scope.getters = {
				sortPlayerQuestionsCorrect: function sortPlayerQuestionsCorrect(row) {
					return row.total_correct / (row.total_answered || 1);
				},
				sortPlayerQuestionsIncorrect: function sortPlayerQuestionsIncorrect(row) {
					return row.total_incorrect / (row.total_answered || 1);
				},
				sortQuestionText: function sortQuestionText(row) {
					return row.text;
				},
				sortQuestionCorrect: function sortQuestionCorrect(row) {
					return row.total_correct / (row.total_answered || 1);
				},
				sortQuestionIncorrect: function sortQuestionIncorrect(row) {
					return row.total_incorrect / (row.total_answered || 1);
				},
				sortQuestionCorrectFirst: function sortQuestionCorrectFirst(row) {
					return row.total_correct_first / (row.total_first || 1);
				},
				sortQuestionIncorrectFirst: function sortQuestionIncorrectFirst(row) {
					return row.total_incorrect_first / (row.total_first || 1);
				},
				sortQuestionCorrectSecond: function sortQuestionCorrectSecond(row) {
					return row.total_correct_second / (row.total_second || 1);
				},
				sortQuestionIncorrectSecond: function sortQuestionIncorrectSecond(row) {
					return row.total_incorrect_second / (row.total_second || 1);
				},
				sortQuestionCorrectThird: function sortQuestionCorrectThird(row) {
					return row.total_correct_third / (row.total_third || 1);
				},
				sortQuestionIncorrectThird: function sortQuestionIncorrectThird(row) {
					return row.total_incorrect_third / (row.total_third || 1);
				}
			};

            $scope.actions = {
				loadUsers: function() {
					var startDate = $scope.viewmodel.playerDates.startDate,
						endDate = $scope.viewmodel.playerDates.endDate;

					$scope.loadingUsers = true;

					$scope.viewmodel.players = [];

					statsService.getHighScore('highest', startDate, endDate).then(function(response) {
						$scope.viewmodel.highScore = response.score || 0;
					});

					statsService.getSessionStats(startDate, endDate).then(function(stats) {
						var duration = $filter('formatDuration')(stats.average_time).split(':');
						$scope.viewmodel.timeMin = duration[0];
						$scope.viewmodel.timeSec = duration[1];
						$scope.viewmodel.totalSessions = stats.total_sessions;
					});

					playerService.getTotalPlayers(startDate, endDate).then(function(total) {
						$scope.viewmodel.totalPlayers = total;
					});

					playerService.getNewPlayers(startDate, endDate).then(function(total) {
						$scope.viewmodel.newPlayers = total;
					});

					playerService.getPlayers(startDate, endDate).then(function(response) {
						$scope.viewmodel.players = response;
						$scope.loadingUsers = false;
					});
				},
				loadQuestions: function() {
					$scope.loadingQuestions = true;

					$scope.viewmodel.questions = [];

					statsService.getQuestionStats($scope.viewmodel.questionDates.startDate, $scope.viewmodel.questionDates.endDate).then(function(response) {
						$scope.viewmodel.questions = response;
						$scope.loadingQuestions = false;
					});
				},
				exportPlayerStats: function() {
					var reportData = [], i = 0;

					reportData.push(['Player', 'Rank', 'High Score', 'Total Sessions', 'Avg. Session Duration', 'Questions Correct', 'Questions Incorrect', 'Total Questions']);

					for (i = 0; i < $scope.viewmodel.players.length; i++) {
						reportData.push([
							$scope.viewmodel.players[i].display_name,
							$scope.viewmodel.players[i].rank,
							$scope.viewmodel.players[i].high_score,
							$scope.viewmodel.players[i].total_sessions,
							$filter('formatDuration')($scope.viewmodel.players[i].average_time),
							$filter('percentage')($scope.viewmodel.players[i].total_correct / ($scope.viewmodel.players[i].total_answered || 1), 2),
							$filter('percentage')($scope.viewmodel.players[i].total_incorrect / ($scope.viewmodel.players[i].total_answered || 1), 2),
							$scope.viewmodel.players[i].total_answered
						]);
					}

					return reportData;
				},
				exportQuestionStats: function() {
					var reportData = [], i = 0;

					reportData.push(['','','','','','1st Attemp','','2nd Attempt','','3rd Attempt', '']);
					reportData.push(['Question', 'Avg. Time', 'Correct', 'Incorrect', 'Attempts', 'Correct', 'Incorrect', 'Correct', 'Incorrect', 'Correct', 'Incorrect']);

					for (i = 0; i < $scope.viewmodel.questions.length; i++) {
						reportData.push([
							$scope.viewmodel.questions[i].text,
							$filter('formatDuration')($scope.viewmodel.questions[i].average_time),
							$filter('percentage')($scope.viewmodel.questions[i].total_correct / ($scope.viewmodel.questions[i].total_answered || 1), 2),
							$filter('percentage')($scope.viewmodel.questions[i].total_incorrect / ($scope.viewmodel.questions[i].total_answered || 1), 2),
							$scope.viewmodel.questions[i].total_answered,
							$filter('percentage')($scope.viewmodel.questions[i].total_correct_first / ($scope.viewmodel.questions[i].total_first || 1), 2),
							$filter('percentage')($scope.viewmodel.questions[i].total_incorrect_first / ($scope.viewmodel.questions[i].total_first || 1), 2),
							$filter('percentage')($scope.viewmodel.questions[i].total_correct_second / ($scope.viewmodel.questions[i].total_second || 1), 2),
							$filter('percentage')($scope.viewmodel.questions[i].total_incorrect_second / ($scope.viewmodel.questions[i].total_second || 1), 2),
							$filter('percentage')($scope.viewmodel.questions[i].total_correct_third / ($scope.viewmodel.questions[i].total_third || 1), 2),
							$filter('percentage')($scope.viewmodel.questions[i].total_incorrect_third / ($scope.viewmodel.questions[i].total_third || 1), 2)
						]);
					}

					return reportData;
				},
				playerDetails: function (player) {
					var modalInstance = $uibModal.open({
						animation: true,
						templateUrl: 'templates/views/player.html',
						controller: 'PlayerController',
						size: 'lg',
						backdrop: true,
						resolve: {
							player: function () {
								return player;
							},
						},
					});
				},
                createAlert: function (message, alertType) {
                    $scope.data.alerts = [];
                    $scope.data.alerts.push({ msg: message, type: alertType });
                },
            };

			$scope.$on('refresh', function(e, msg) {
				$scope.initialize();
			});

            $scope.initialize();
        }
    ]);