angular.module('tgaAdmin')
    .service('gameService', ['$http', 'envService',
        function ($http, envService) {

            var serverAddress = envService.read('serverAddress');
			var games = null;

            return ({
                getGame: getGame,
                getGames: getGames,
                addGame: addGame,
                updateGame: updateGame,
                deleteGame: deleteGame,
				getQuestion: getQuestion,
                getQuestions: getQuestions,
                addQuestion: addQuestion,
				updateQuestion: updateQuestion,
				getTracks: getTracks,
				getFields: getFields,
				getGameTypes: getGameTypes
            });

			function getGame(gameId) {
				return $http({
					method: 'GET',
					url: serverAddress + 'game/' + gameId
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function getGames() {
				if(!games) {
					games = $http({
						method: 'GET',
						url: serverAddress + 'game'
					}).then(
						function (response) {
							return response.data;
						},
						function (error) {
							throw error.data;
						}
					);
				}

				return games;
            }

			function addGame(data) {
				// Clear games cache
				games = null;

				return $http({
					method: 'POST',
					url: serverAddress + 'game',
					data: data
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function updateGame(data) {
				// Clear games cache
				games = null;

				return $http({
					method: 'PUT',
					url: serverAddress + 'game',
					data: data
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			/*function updateGame(data) {
				// Clear games cache
				games = null;

				return $http({
					method: 'PUT',
					url: serverAddress + 'game',
					// IMPORTANT!!! You might think this should be set to 'multipart/form-data'
					// but this is not true because when we are sending up files the request
					// needs to include a 'boundary' parameter which identifies the boundary
					// name between parts in this multi-part request and setting the Content-type
					// manually will not set this boundary parameter. For whatever reason,
					// setting the Content-type to 'false' will force the request to automatically
					// populate the headers properly including the boundary parameter.
					headers: { 'Content-Type': undefined },
					// This method will allow us to change how the data is sent up to the server
					// for which we'll need to encapsulate the farmer data in 'FormData'
					transformRequest: function (data) {
						console.log(data)
						var formData = new FormData();

						// remove base64 encoded file
						for (var i = 0; i < data.game.questions.length; i++) {
							delete data.game.questions[i].audio.base64;
						}

						// need to convert our json object to a string version of json otherwise
						// the browser will do a 'toString()' on the object which will result
						// in the value '[Object object]' on the server.
						formData.append('game', angular.toJson(data.game));

						// add the files
						for (var i = 0; i < data.questions.length; i++) {
							console.log(data.questions[i].audio)
							formData.append('audio[]', data.questions[i].audio.file || '');
						}

						return formData;
					},
					data: {
						game: angular.copy(data),
						questions: data.questions
					}
				});
			}*/

			function deleteGame(gameId) {
				// Clear games cache
				games = null;

				return $http({
					method: 'DELETE',
					url: serverAddress + 'game/' + gameId
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function getQuestion(questionId) {
				return $http({
					method: 'GET',
					url: serverAddress + 'question/' + questionId
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function getQuestions(gameId) {
				return $http({
					method: 'GET',
					url: serverAddress + 'question',
					params: {
						game_id: gameId
					}
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function addQuestion(data) {
				return $http({
					method: 'POST',
					url: serverAddress + 'question',
					data: data
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function updateQuestion(data) {
				return $http({
					method: 'PUT',
					url: serverAddress + 'question',
					data: data
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function getTracks() {
				return $http({
					method: 'GET',
					url: serverAddress + 'game/tracks'
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function getFields() {
				return $http({
					method: 'GET',
					url: serverAddress + 'game/fields'
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function getGameTypes() {
				return [{
					key: 'jump',
					name: 'Jump'
				}, {
					key: 'match',
					name: 'Match'
				}];
            }

        }]);