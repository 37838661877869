angular.module('tgaAdmin')
    .controller('SettingsController', ['$scope', '$state', '$timeout', 'settingsService',
        function ($scope, $state, $timeout, settingsService) {
            $scope.data = {
                alerts: []
            };

            $scope.viewmodel = {
                settings: {}
            };

            $scope.initialize = function () {
				settingsService.getSettings().then(function(settings) {
					$scope.viewmodel.settings = settings;
				});
            };

			$scope.checkImage = function (image) {
				//console.log(image)
				$scope.data.alerts = [];

				if(!image) {
					$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Invalid file format.', 'danger');
					return;
				}

				var extension = /(?:\.([^.]+))?$/;
				var ext = extension.exec(image.filename)[1];
				var allowed = /png/i;
				if (!allowed.test(ext)) {
					image.base64 = null;
					$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Invalid file format.', 'danger');
					return;
				}

				// Don't allow images larger than 5MB.
				if(image.filesize > 5242880) {
					image.base64 = null;
					$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Image files cannot be more than 5 MB in size.', 'danger');
					return;
				}
			};

            $scope.actions = {
				submit: function(form) {
					if (form.$invalid) {
						$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Please check the form for errors.', 'danger');
						return;
					}

					form.processing = true;

					settingsService.updateSettings($scope.viewmodel.settings).then(
						function(settings) {
							$scope.actions.createAlert('<span class="fa fa-check"></span> Settings updated.', 'success');
							form.processing = false;
							$scope.viewmodel.settings = settings;
							$scope.$emit('refreshSettings', true);
						},
						function(error) {
							$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> <strong>Error!</strong> ' + error.message, 'danger');
							form.processing = false;
						}
					);
				},
                createAlert: function (message, alertType) {
                    $scope.data.alerts = [];
                    $scope.data.alerts.push({ msg: message, type: alertType });
                },
            };

            $scope.initialize();
        }
    ]);