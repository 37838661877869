angular.module('tgaAdmin')
    .controller('NavigationController', ['$scope', '$rootScope', '$state', 'userService', 'gameService', 'settingsService',
        function ($scope, $rootScope, $state, userService, gameService, settingsService) {
			$scope.$state = $state;

			$scope.data = {
				games: [],
				isAdmin: userService.checkUserType(['admin', 'super_admin']),
				logo: '',
				defaultLogo: 'assets/images/tga-icon.png'
			}

			$scope.viewmodel = {

			}

			$scope.initialize = function() {
				$scope.getGames();
				$scope.getSettings();
			}

			$scope.getGames = function() {
				gameService.getGames().then(function(games) {
					$scope.data.games = games;
				});
			}

			$scope.getSettings = function() {
				settingsService.getSettings().then(function(settings) {
					if(settings.logo) {
						$scope.data.logo = settings.uploads_url + '/' + settings.logo;
					} else {
						$scope.data.logo = $scope.data.defaultLogo;
					}
				});
			}

			$scope.actions = {

            };

			$rootScope.$on('refreshNav', function(e, msg) {
				$scope.getGames();
			});

			$rootScope.$on('refreshSettings', function(e, msg) {
				$scope.getSettings();
			});

			$scope.initialize();
        }
    ]);