angular.module('tgaAdmin')
    .controller('LoginController', ['$scope', '$state', 'userService', 'settingsService',
        function ($scope, $state, userService, settingsService) {
			if(userService.isLoggedIn()) {
				$state.go('home');
			}

            $scope.data = {
                alerts: [],
				logo: '',
				defaultLogo: 'assets/images/tga-logo.png'
            };

            $scope.viewmodel = {
				username: '',
				password: '',
				remember: false
            };

            $scope.initialize = function () {
				settingsService.getSettings().then(function(settings) {
					if(settings.logo) {
						$scope.data.logo = settings.uploads_url + '/' + settings.logo;
					} else {
						$scope.data.logo = $scope.data.defaultLogo;
					}
				});
            };

            $scope.actions = {
                login: function () {
                    if (!$scope.loginForm.$invalid) {
						$scope.loading = true;

                        userService.login($scope.viewmodel).then(function () {
                            $state.go('home');
                        }, function (err) {
							$scope.loading = false;
                            $scope.actions.createAlert('Invalid username or password.', 'danger');
                        });
                    }
                },
                createAlert: function (message, alertType) {
                    $scope.data.alerts = [];
                    $scope.data.alerts.push({ msg: message, type: alertType });
                },
            };

            $scope.initialize();
        }
    ]);