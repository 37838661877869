angular.module('tgaAdmin')
    .controller('UsersController', ['$scope', '$state', 'userService', 'flashService',
        function ($scope, $state, userService, flashService) {
            $scope.data = {
                alerts: [],
            };

            $scope.viewmodel = {
                users: [],
            };

            $scope.initialize = function () {
				$scope.loadingUsers = true;

				userService.getUsers().then(function(users) {
					$scope.viewmodel.users = users;
					$scope.loadingUsers = false;
				});
            };

            $scope.actions = {
                createAlert: function (message, alertType) {
                    $scope.data.alerts = [];
                    $scope.data.alerts.push({ msg: message, type: alertType });
                },
            };

            $scope.initialize();
        }
    ]);