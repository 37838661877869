angular.module('tgaAdmin')
    .controller('PlayerController', ['$scope', '$uibModalInstance', '$filter', 'playerService', 'player',
        function ($scope, $uibModalInstance, $filter, playerService, player) {
			$scope.data = angular.copy(player);

			$scope.viewmodel = {
				sessions: [],
				questions: []
			};

			$scope.initialize = function() {
				$scope.loading = true;

				playerService.getPlayer(player.id).then(function(data) {
					var duration = $filter('formatDuration')(data.average_time).split(':');
					$scope.viewmodel.timeMin = duration[0];
					$scope.viewmodel.timeSec = duration[1];

					$scope.viewmodel.high_score = data.high_score;
					$scope.viewmodel.total_sessions = data.total_sessions;
					$scope.viewmodel.sessions = data.sessions;
					$scope.viewmodel.questions = data.questions;

					$scope.loading = false;
				});
			};

			$scope.getters = {
				sortPlayerQuestionsCorrect: function sortPlayerQuestionsCorrect(row) {
					return row.total_correct / (row.total_answered || 1);
				},
				sortPlayerQuestionsIncorrect: function sortPlayerQuestionsIncorrect(row) {
					return row.total_incorrect / (row.total_answered || 1);
				},
				sortQuestionText: function sortQuestionText(row) {
					return row.text;
				},
				sortQuestionCorrect: function sortQuestionCorrect(row) {
					return row.total_correct / (row.total_answered || 1);
				},
				sortQuestionIncorrect: function sortQuestionIncorrect(row) {
					return row.total_incorrect / (row.total_answered || 1);
				},
				sortQuestionCorrectFirst: function sortQuestionCorrectFirst(row) {
					return row.total_correct_first / (row.total_first || 1);
				},
				sortQuestionIncorrectFirst: function sortQuestionIncorrectFirst(row) {
					return row.total_incorrect_first / (row.total_first || 1);
				},
				sortQuestionCorrectSecond: function sortQuestionCorrectSecond(row) {
					return row.total_correct_second / (row.total_second || 1);
				},
				sortQuestionIncorrectSecond: function sortQuestionIncorrectSecond(row) {
					return row.total_incorrect_second / (row.total_second || 1);
				},
				sortQuestionCorrectThird: function sortQuestionCorrectThird(row) {
					return row.total_correct_third / (row.total_third || 1);
				},
				sortQuestionIncorrectThird: function sortQuestionIncorrectThird(row) {
					return row.total_incorrect_third / (row.total_third || 1);
				}
			};

			$scope.actions = {
				ok: function (data) {
					$uibModalInstance.close(data);
				},
				cancel: function (data) {
					$uibModalInstance.dismiss(data);
				},
            };

			$scope.initialize();
        }
    ]);