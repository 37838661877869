angular.module('tgaAdmin')
    .controller('UserController', ['$scope', '$state', 'userService', 'flashService',
        function ($scope, $state, userService, flashService) {

			$scope.showBack = true;

            $scope.data = {
                alerts: [],
				userId: $state.params.id || null,
				userTypes: [{
					id: 'admin',
					value: 'Admin'
				},{
					id: 'user',
					value: 'User'
				}]
            };

            $scope.viewmodel = {
				users: [],
                user: null,
				password: null,
				confirmPassword: null
            };

            $scope.initialize = function () {
				if($scope.data.userId) {
					userService.getUser($scope.data.userId).then(function(user) {
						$scope.viewmodel.user = user;

						// @TODO: use resolve to handle this
						$scope.title = 'Edit User: ' + user.first_name + ' ' + user.last_name;
					});
				}
            };

            $scope.actions = {
				submit: function(form) {
					if (form.$invalid) {
						$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Please check the form for errors.', 'danger');
						return;
					}

					if((form.$name === 'passwordForm' || $scope.data.userId === null) && $scope.viewmodel.password !== $scope.viewmodel.confirmPassword) {
						$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Password and password confirmation fields do not match.', 'danger');
						return;
					}

					form.processing = true;

					if($scope.data.userId) {
						var data = form.$name === 'passwordForm' ? {password: $scope.viewmodel.password} : $scope.viewmodel.user;

						userService.updateUser(data).then(
							function(user) {
								if(form.$name === 'passwordForm') {
									$scope.actions.createAlert('<span class="fa fa-check"></span> Password updated.', 'success');
								} else {
									$scope.actions.createAlert('<span class="fa fa-check"></span> User information updated.', 'success');
								}

								form.processing = false;
							},
							function(error) {
								$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> <strong>Error!</strong> ' + error.message, 'danger');
								form.processing = false;
							}
						);
					} else {
						$scope.viewmodel.user.password = $scope.viewmodel.password;

						userService.addUser($scope.viewmodel.user).then(
							function(user) {
								flashService.set('<span class="fa fa-check"></span> User added.', 'success');
								form.processing = false;
								$state.go('users');
							},
							function(error) {
								$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> <strong>Error!</strong> ' + error.message, 'danger');
								form.processing = false;
							}
						);
					}
				},
                createAlert: function (message, alertType) {
                    $scope.data.alerts = [];
                    $scope.data.alerts.push({ msg: message, type: alertType });
                },
            };

            $scope.initialize();
        }
    ]);