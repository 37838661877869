angular.module('tgaAdmin')
    .service('modalService', ['$uibModal',
        function ($uibModal) {

            var defaultSettings = {
                backdrop: 'static',
                keyboard: false,
                animation: true,
                templateUrl: './templates/partials/modal.html'
            };

            var defaultOptions = {
                title: 'Proceed?',
                message: 'Perform this action?',
                closeButton: 'Close',
                actionButton: 'OK'
            };

            this.showModal = function (customOptions, customDefaults) {
                if (!customDefaults) customDefaults = {};
                return this.show(customOptions, customDefaults);
            };

            this.show = function (customOptions, customDefaults) {
                // Create temp objects to work with since we're in a singleton service
                var tempDefaults = {};
                var tempOptions = {};

                // Map angular-ui modal custom defaults to modal defaults defined in service
                angular.extend(tempDefaults, defaultSettings, customDefaults);

                // Map modal.html $scope custom properties to defaults defined in service
                angular.extend(tempOptions, defaultOptions, customOptions);

                if (!tempDefaults.controller) {
                    tempDefaults.controller = function ($scope, $uibModalInstance) {
                        $scope.data = tempOptions;

                        $scope.actions = {
                            ok: function (result) {
                                $uibModalInstance.close(result);
                            },
                            close: function (result) {
                                $uibModalInstance.dismiss('cancel');
                            }
                        }
                    };
                }

                return $uibModal.open(tempDefaults).result;
            };

        }]);