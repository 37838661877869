angular.module('tgaAdmin')
    .controller('GameController', ['$scope', '$state', '$document', '$timeout', '$location', '$filter', 'gameService', 'assetService', 'flashService', 'Slug', 'fileReader',
        function ($scope, $state, $document, $timeout, $location, $filter, gameService, assetService, flashService, Slug, fileReader) {

            $scope.data = {
                alerts: [],
				gameId: $state.params.id || null,
				limit: 5,
				questionTypes: [{
					id: 'multiple_choice',
					name: 'Multiple Choice'
				}, {
					id: 'multiple_select',
					name: 'Multiple Select'
				}, {
					id: 'slider',
					name: 'Slider'
				}, {
					id: 'image',
					name: 'Image Match'
				}],
				url: $location.$$protocol + '://' + $location.$$host,
				themes: [],
				tracks: [],
				yesno: [{
					id: true,
					name: 'Yes'
				}, {
					id: false,
					name: 'No'
				}],
				registrationFields: {
					first_name: {
						key: 'first_name',
						label: 'First Name',
						condition: 'required',
						disabled: true
					},
					email: {
						key: 'email',
						label: 'E-mail Address',
						condition: 'required',
						disabled: true
					},
					last_name: {
						key: 'last_name',
						label: 'Last Name',
						condition: null
					},
					phone: {
						key: 'phone',
						label: 'Phone Number',
						condition: null
					},
					employee_id: {
						key: 'employee_id',
						label: 'Employee ID',
						condition: null
					}
				},
				registrationFieldOptions: [{
					id: 'required',
					name: 'Required'
				}, {
					id: 'optional',
					name: 'Optional'
				}, {
					id: null,
					name: 'Disabled'
				}],
				gameTypes: gameService.getGameTypes(),
				multipleChoiceTypes: [{
					id: 'standard',
					name: 'Standard'
				}, {
					id: 'image',
					name: 'Image'
				}],
				questionGroups: [{
					id: 'pre_game',
					name: 'Pre-Game'
				}, {
					id: 'in_game',
					name: 'In-Game'
				}]
            };

			$scope.numeric = /^\d+$/;

			$scope.addMoreItems = function() {
				$scope.data.limit += 5;
				//console.log($scope.data.limit)
			};

			$scope.sortableOptions = {
				items: '.panel',
				containment: 'parent',
				revert: 250,
				forcePlaceholderSize: true,
				tolerance: 'pointer',
				cursor: 'move',
				handle: '.question-handle',
				placeholder: 'ui-sortable-placeholder',
				helper: 'clone'
			};

			$scope.answerSortableOptions = {
				items: 'tr',
				containment: '.tab-content',
				revert: 250,
				forcePlaceholderSize: true,
				tolerance: 'pointer',
				cursor: 'move',
				handle: '.answer-handle',
				placeholder: 'ui-sortable-placeholder',
				helper: 'original',
				scroll: false,
				start: function(e, ui){
					ui.placeholder.height(ui.item.height());
				}
			};

            $scope.viewmodel = {
                game: {
					time_per_question: 60,
					questions: [],
					registration: {
						required: false,
						fields: []
					},
					splash: {
						logo: {},
						title: {},
						background: {}
					}
				}
            };

            $scope.initialize = function () {
				window.scrollTo(0,0);

				if($scope.data.gameId) {
					$scope.loading = true;

					gameService.getGame($scope.data.gameId).then(
						function(game) {
							// @TODO: use resolve to handle this
							$scope.title = 'Edit Game: ' + game.name;
							$scope.viewmodel.game = game;

							if(!$scope.viewmodel.game.questions.length) {
								$scope.actions.addQuestion();
							}

							for(var i in game.registration.fields) {
								if(game.registration.fields[i].key === 'initials') {
									delete game.registration.fields[i];
									$scope.data.registrationFields['first_name'].key = 'initials';
									$scope.data.registrationFields['first_name'].label = 'Initials';
									break;
								}
							}

							$scope.viewmodel.game.registration.fields = angular.extend({}, $scope.data.registrationFields, _.keyBy(game.registration.fields, 'key'));

							$scope.actions.changeGameType();

							for(var i = 0; i < $scope.viewmodel.game.questions.length; i++) {
								$scope.actions.changeQuestionType($scope.viewmodel.game.questions[i]);
							}

							$scope.loading = false;
						},
						function() {
							// Invalid Game ID
							$state.go('addGame');
						}
					);
				}
            };

            $scope.actions = {
				submit: function(form) {
					$scope.data.alerts = [];

					if (form.$invalid) {
						$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Please check the form for errors.', 'danger');
						var field = $(':input.ng-invalid:visible:first');
						$('html, body').animate({ scrollTop: field.parent().offset().top - 110 }, 450);
						return;
					}

					$scope.processing = true;

					if($scope.data.gameId) {
						// Editing game
						gameService.updateGame($scope.viewmodel.game).then(
							function(game) {
								$scope.actions.createAlert('<span class="fa fa-check"></span> Game updated successfully.', 'success');
								$scope.processing = false;
								$scope.$emit('refreshNav', true);
								$scope.title = 'Edit Game: ' + game.name;
								$scope.viewmodel.game = game;

								for(var i in game.registration.fields) {
									if(game.registration.fields[i].key === 'initials') {
										delete game.registration.fields[i];
										$scope.data.registrationFields['first_name'].key = 'initials';
										$scope.data.registrationFields['first_name'].label = 'Initials';
										break;
									}
								}
								$scope.viewmodel.game.registration.fields = angular.extend({}, $scope.data.registrationFields, _.keyBy(game.registration.fields, 'key'))

								for(var i in $scope.viewmodel.game.questions) {
									$scope.actions.changeQuestionType($scope.viewmodel.game.questions[i]);
								}

								$scope.actions.updateTheme();
							},
							function(error) {
								$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> <strong>Error!</strong> ' + error.message, 'danger');
								$scope.processing = false;

								if(error.fields) {
									for(var i = 0; i < error.fields.length; i++) {
										if($scope.gameForm[error.fields[i]]) {
											$scope.gameForm[error.fields[i]].$setValidity('required', false);
										}
									}
								}
							}
						);
					} else {
						// Adding new game
						gameService.addGame($scope.viewmodel.game).then(
							function(game) {
								flashService.set('<span class="fa fa-check"></span> Game added successfully.', 'success');
								$scope.processing = false;
								$scope.$emit('refreshNav', true);
								$state.go('editGame', {id: game.game_id});
							},
							function(error) {
								$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> <strong>Error!</strong> ' + error.message, 'danger');
								$scope.processing = false;
							}
						);
					}
				},
				addQuestion: function() {
					$scope.data.limit = 500;

					var question = {
						question_id: null,
						question_type: 'multiple_choice',
						question_group: null,
						text: null,
						image: null,
						audio: null,
						video: null,
						time: $scope.viewmodel.game.time_per_question,
						randomize_answers: true,
						answers: [{
							answer_id: null,
							text: null,
							image: null,
							response: null,
							correct: false
						}, {
							answer_id: null,
							text: null,
							image: null,
							response: null,
							correct: false
						}]
					};

					$scope.actions.changeQuestionType(question);
					$scope.viewmodel.game.questions.push(question);

					setTimeout(function() {
						$('html, body').animate({ scrollTop: $('.panel').last().offset().top - 130 }, 450);
					}, 100);
				},
				addAnswer: function(question) {
					question.answers.push({
						answer_id: null,
						text: null,
						image: null,
						response: null,
						correct: false
					});
				},
				deleteQuestion: function(question) {
					$scope.viewmodel.game.questions.splice($scope.viewmodel.game.questions.indexOf(question), 1);
				},
				requireRegistration: function() {
					if($scope.viewmodel.game.registration.required === true) {
						$scope.viewmodel.game.registration.fields = angular.copy($scope.data.registrationFields);
					} else {
						$scope.viewmodel.game.registration.fields = [];
					}
				},
				toggleInitials: function(key) {
					if(key === 'first_name') {
						$scope.viewmodel.game.registration.fields['first_name'].label = 'First Name';
						$scope.viewmodel.game.registration.fields['first_name'].key = key;
					} else {
						$scope.viewmodel.game.registration.fields['first_name'].label = 'Initials';
						$scope.viewmodel.game.registration.fields['first_name'].key = key;
					}
				},
				changeGameType: function() {
					var totalQuestions = $scope.viewmodel.game.questions.length;
					$scope.data.limit = 5;

					assetService.getThemes($scope.viewmodel.game.game_type).then(function(themes) {
						$scope.data.themes = themes;

						$scope.actions.updateTheme();
					});

					assetService.getTracks($scope.viewmodel.game.game_type).then(function(tracks) {
						$scope.data.tracks = tracks;
						$scope.data.tracks.unshift({
							id: null,
							name: 'No Track',
							url: null
						});
					});

					if($scope.viewmodel.game.game_type === 'jump') {
						$scope.viewmodel.game.time_per_level = 0;
						$scope.viewmodel.game.total_levels = null;
					} else {
						$scope.viewmodel.game.time_per_question = 0;
					}
				},
				changeQuestionType: function(question) {
					var totalAnswers = question.answers.length;

					// Set min/max answers
					if(question.question_type === 'slider') {
						question.min_answers = 2;
						question.max_answers = 5;
					} else if(question.question_type === 'image') {
						question.min_answers = 2;
						question.max_answers = 4;
					} else {
						question.min_answers = 2;
						question.max_answers = 10;
					}

					// Set image vs text
					if(question.question_type === 'image') {
						for(var i = 0; i < totalAnswers; i++) {
							question.answers[i].text = null;
						}
					} else {
						for(var i = 0; i < totalAnswers; i++) {
							question.answers[i].image = null;
						}
					}

					// Set max correct
					if(question.question_type === 'multiple_select' || question.question_type === 'image') {
						question.max_correct = null;
					} else {
						question.max_correct = 1;

						var totalCorrect = $filter('filter')(question.answers, {correct: true}, true).length;

						if(totalCorrect > 1) {
							for(var i = 0; i < totalAnswers; i++) {
								question.answers[i].correct = false;
							}
						}
					}

					// Remove excess answers based on new max_answers
					if(question.answers.length > question.max_answers) {
						question.answers.splice(question.max_answers, question.answers.length - question.max_answers);
					}
				},
				checkImage: function (image) {
					//console.log(image)
					$scope.data.alerts = [];

					if(!image) {
						$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Invalid file format.', 'danger');
						return;
					}

					var extension = /(?:\.([^.]+))?$/;
					var ext = extension.exec(image.filename)[1];
					var allowed = /jpeg|jpg|gif|png/i;
					if (!allowed.test(ext)) {
						image.base64 = null;
						//image.invalid_type = true;
						$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Invalid file format.', 'danger');
						return;
					}

					// Don't allow images larger than 5MB.
					if(image.filesize > 5242880) {
						image.base64 = null;
						//image.invalid_size = true;
						$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Image files cannot be more than 5 MB in size.', 'danger');
						return;
					}
				},
				checkAudio: function (audio) {
					//console.log(audio)
					$scope.data.alerts = [];

					var allowed = (/audio\/mpeg|audio\/mp3|audio\/mp4|audio\/ogg|audio\/x+|wav/).test(audio.file.type);

					if(!allowed) {
						audio = null;
						$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Invalid file format.', 'danger');
						return;
					};

					if(audio.file.size > 5242880) {
						audio = null;
						$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Audio files cannot be more than 5 MB in size.', 'danger');
						return;
					}

					fileReader.readAsDataUrl(audio.file, $scope).then(function(result) {
						audio.base64 = result;
						audio.filename = audio.file.name;
					});
				},
				updateTheme: function() {
					// Update Splash Screen based on theme
					if(!$scope.viewmodel.game.assets.theme) {
						if(!$scope.viewmodel.game.splash.title.type) {
							$scope.viewmodel.game.splash.title.image = null;
						}

						if(!$scope.viewmodel.game.splash.background.type) {
							$scope.viewmodel.game.splash.background.image = null;
						}
					} else {
						var theme = $filter('filter')($scope.data.themes, {key: $scope.viewmodel.game.assets.theme}, true);

						if(!$scope.viewmodel.game.splash.title.type) {
							if(theme.length) {
								$scope.viewmodel.game.splash.title.image = {
									url: theme[0].splash.title
								}
							}
						}

						if(!$scope.viewmodel.game.splash.background.type) {
							if(theme.length) {
								$scope.viewmodel.game.splash.background.image = {
									url: theme[0].splash.background
								}
							}
						}
					}
				},
				changeSplashLogo: function() {
					$scope.viewmodel.game.splash.logo.text = null;
					$scope.viewmodel.game.splash.logo.image = null;
				},
				changeSplashTitle: function() {
					$scope.viewmodel.game.splash.title.text = null;
					$scope.viewmodel.game.splash.title.image = null;

					if($scope.viewmodel.game.assets.theme && !$scope.viewmodel.game.splash.title.type) {
						$scope.viewmodel.game.splash.title.image = {
							url: $filter('filter')($scope.data.themes, {key: $scope.viewmodel.game.assets.theme}, true)[0].splash.title
						}
					}
				},
				changeSplashBackground: function() {
					$scope.viewmodel.game.splash.background.image = null;

					if($scope.viewmodel.game.assets.theme && !$scope.viewmodel.game.splash.background.type) {
						$scope.viewmodel.game.splash.background.image = {
							url: $filter('filter')($scope.data.themes, {key: $scope.viewmodel.game.assets.theme}, true)[0].splash.background
						}
					}
				},
                createAlert: function (message, alertType) {
                    $scope.data.alerts = [];
                    $scope.data.alerts.push({ msg: message, type: alertType });
					//$timeout(function () { $scope.data.alerts.splice($scope.data.alerts.length - 1, 1) }, 7000);
                },
				makeSlug: function() {
					if(!$scope.viewmodel.game.slug) {
						$scope.viewmodel.game.slug = Slug.slugify($scope.viewmodel.game.name);
					}
				},
				tabChanged: function() {
					window.scrollTo(0,0);
				},
				deleteGame: function() {
					if($scope.data.gameId) {
						var status = confirm('Are you sure? You will lose all data associated with this game including questions, answers and analytics.');

						if(status) {
							$scope.deleting = true;

							gameService.deleteGame($scope.data.gameId).then(
								function(game) {
									flashService.set('<span class="fa fa-check"></span> Game deleted successfully.', 'success');
									$scope.deleting = false;
									$scope.$emit('refreshNav', true);
									$state.go('addGame');
								},
								function(error) {
									$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> <strong>Error!</strong> ' + error.message, 'danger');
									$scope.deleting = false;
								}
							);
						}
					}
				}
            };

            $scope.initialize();
        }
    ]);