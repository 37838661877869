app.directive('fileUpload', function() {
	return {
		require: 'ngModel',
		link: function (scope, el, attrs, ngModel) {
			el.on('change', function(e) {
				var files = {
					file: el[0].files
				};

				if(el[0].files.length === 1) {
					files.file = el[0].files[0];
				}

				ngModel.$setViewValue(files);
			});
		}
	};
});