app.config(function(envServiceProvider) {
	// set the domains and variables for each environment
	envServiceProvider.config({
		domains: {
			development: ['localhost'],
			production: ['tga-cms.trainingarcade.com']
		},
		vars: {
			development: {
				enableDevSettings:true,
				serverAddress:'http://localhost/tga/tga-api-backend/api/v5/'
			},
			production: {
				enableDevSettings:false,
				serverAddress:'https://tga-cms-backend.trainingarcade.com/api/v5/'
			}
		}
	});

	// run the environment check, so the comprobation is made
	// before controllers and services are built
	envServiceProvider.check();

	// force set development toggle
	// envServiceProvider.set('development');
});