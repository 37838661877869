angular.module('tgaAdmin')
    .controller('AccountController', ['$scope', '$state', '$timeout', 'userService',
        function ($scope, $state, $timeout, userService) {
            $scope.data = {
                alerts: []
            };

            $scope.viewmodel = {
                user: null,
				password: null,
				confirmPassword: null
            };

            $scope.initialize = function () {
				userService.getUser(userService.getUserData().id).then(function(user) {
					$scope.viewmodel.user = user;
				});
            };

            $scope.actions = {
				submit: function(form) {
					if (form.$invalid) {
						$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Please check the form for errors.', 'danger');
						return;
					}

					if(form.$name === 'passwordForm' && $scope.viewmodel.password !== $scope.viewmodel.confirmPassword) {
						$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> Password and password confirmation fields do not match.', 'danger');
						return;
					}

					form.processing = true;

					var data = form.$name === 'passwordForm' ? {password: $scope.viewmodel.password} : $scope.viewmodel.user;

					userService.updateUser(data, true).then(
						function(user) {
							if(form.$name === 'passwordForm') {
								$scope.actions.createAlert('<span class="fa fa-check"></span> Your password was updated.', 'success');
							} else {
								$scope.actions.createAlert('<span class="fa fa-check"></span> Your account information was updated.', 'success');
							}

							form.processing = false;
						},
						function(error) {
							$scope.actions.createAlert('<span class="fa fa-exclamation-triangle"></span> <strong>Error!</strong> ' + error.message, 'danger');
							form.processing = false;
						}
					);
				},
                createAlert: function (message, alertType) {
                    $scope.data.alerts = [];
                    $scope.data.alerts.push({ msg: message, type: alertType });
                },
            };

            $scope.initialize();
        }
    ]);