angular.module('tgaAdmin')
	.controller('FlashController', ['$scope', '$rootScope', 'flashService',
		function ($scope, $rootScope, flashService) {
			$scope.data = {
				alerts: []
			}

			$rootScope.$on('$stateChangeSuccess', function () {
				$scope.data.alerts = flashService.get();
			});

		}]);