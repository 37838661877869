'use strict';
var app = angular.module('tgaAdmin', [
	'environment',
	'LocalStorageModule',
	'pascalprecht.translate',
	'ui.router',
	'ui.bootstrap',
	'countUpModule',
	'smart-table',
	'ngSanitize',
	'ngCsv',
	'daterangepicker',
	'ui.sortable',
	'naif.base64',
	'slugifier',
	'infinite-scroll',
	'ngAnimate',
	'angular-stripe',
	'payment'
]).controller('AppController', ['$scope', '$rootScope', '$window', '$state', '$location', 'envService', 'userService', '$uibModalStack', 'settingsService',
	function ($scope, $rootScope, $window, $state, $location, envService, userService, $uibModalStack, settingsService) {
		console.log('app controller page init');

		$scope.data = {
			company: null
		}

		$scope.state = $state;

		$scope.initialize = function () {
			settingsService.getSettings().then(function(settings) {
				$scope.data.company = settings.company;

				if($scope.state.current && $scope.state.current.data) {
					$window.document.title = $scope.state.current.data.title;

					if($scope.data.company) {
						$window.document.title += ' | ' + $scope.data.company;
					}
				}
			});
		};

		$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
			if ($uibModalStack.getTop()) {
				if($uibModalStack.getTop().value.backdrop !== 'static') {
					$uibModalStack.dismissAll();
				}
				event.preventDefault();
			}

			// whitelist urls that don't require authentication
			switch (toState.name) {
				case 'login':
				case 'register':
				case 'forgotten':
				case 'reset':
					return;
				default:
					if (!userService.isLoggedIn()) {
						event.preventDefault();
						// Pass activity info so we know which activity user is going to
						return $state.go('login', {'reload': true});
					}

					if(toState.data.allowedUsers && !userService.checkUserType(toState.data.allowedUsers)) {
						event.preventDefault();
						return $state.go('home');
					}
			}

			return;
		});

		$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
			if ($window.ga) {
				$window.ga('send', 'pageview', { page: $location.path() });
			}

			$window.document.title = toState.data.title;

			if($scope.data.company) {
				$window.document.title += ' | ' + $scope.data.company;
			}
		});

		$rootScope.$on('refreshSettings', function(e, msg) {
			$scope.initialize();
		});

		$scope.initialize();
	}
]);