app.config(function($stateProvider, $urlRouterProvider) {
	// AngularUI Router uses the concept of states
	// Learn more here: https://github.com/angular-ui/ui-router
	// Set up the various states which the app can be in.
	// Each state's controller can be found in controllers.js
	$stateProvider
	// setup an abstract state for the main view that includes tabs
	.state('login', {
		url: '/login',
		templateUrl: 'templates/views/login.html',
		data: {
			title: 'Login'
		}
	})
	.state('register', {
		url: '/register',
		templateUrl: 'templates/views/register.html',
		data: {
			title: 'Register'
		}
	})
	.state('forgotten', {
		url: '/forgotten',
		templateUrl: 'templates/views/forgotten.html',
		data: {
			title: 'Forgot Password'
		}
	})
	.state('home', {
		url: '/home',
		templateUrl: 'templates/views/home.html',
		data: {
			title: 'Analytics',
			nav: true,
			gameSelector: true
		}
	})
	.state('leaderboard', {
		url: '/leaderboard',
		templateUrl: 'templates/views/leaderboard.html',
		data: {
			title: 'Leaderboard',
			nav: true,
			gameSelector: true
		}
	})
	.state('account', {
		url: '/account',
		templateUrl: 'templates/views/account.html',
		data: {
			title: 'Account',
			nav: true
		}
	})
	.state('addGame', {
		url: '/game/add',
		templateUrl: 'templates/views/game.html',
		data: {
			title: 'New Game',
			nav: true,
			allowedUsers: ['admin', 'super_admin']
		}
	})
	.state('editGame', {
		url: '/game/{id:[0-9]+}',
		templateUrl: 'templates/views/game.html',
		data: {
			title: 'Edit Game',
			nav: true,
			allowedUsers: ['admin', 'super_admin']
		}
	})
	.state('users', {
		url: '/users',
		templateUrl: 'templates/views/users.html',
		data: {
			title: 'Users',
			nav: true,
			allowedUsers: ['admin', 'super_admin']
		}
	})
	.state('addUser', {
		url: '/user/add',
		templateUrl: 'templates/views/user.html',
		data: {
			title: 'Add User',
			nav: true,
			allowedUsers: ['admin', 'super_admin']
		}
	})
	.state('editUser', {
		url: '/user/{id:[0-9]+}',
		templateUrl: 'templates/views/user.html',
		data: {
			title: 'Edit User',
			nav: true,
			allowedUsers: ['admin', 'super_admin']
		}
	})
	.state('settings', {
		url: '/settings',
		templateUrl: 'templates/views/settings.html',
		data: {
			title: 'Settings',
			nav: true
		}
	})
	;

	// if none of the above states are matched, use this as the fallback
	$urlRouterProvider.otherwise('/home');
});