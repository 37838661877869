angular.module('tgaAdmin')
    .controller('LeaderboardController', ['$scope', '$state', '$timeout', 'gameService', 'playerService', 'statsService', '$filter', '$uibModal', 'localStorageService',
        function ($scope, $state, $timeout, gameService, playerService, statsService, $filter, $uibModal, localStorageService) {

            $scope.data = {
                alerts: [],
				filedate: $filter('date')(new Date(), 'yyyy-MM-dd hh-mm-ss'),
				separator: ',',
				decimalSeparator: '.',
				datePickerOptions: {
					clearable: true,
					opens: 'left',
					alwaysShowCalendars: true,
					showCustomRangeLabel: false,
					maxDate: moment().endOf('day'),
					locale: {
						format: 'M/D/YYYY',
						cancelLabel: 'Clear',
					},
					ranges: {
						'Today': [moment(), moment()],
						'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
						'Last 7 Days': [moment().subtract(6, 'days'), moment()],
						'Last 30 Days': [moment().subtract(29, 'days'), moment()],
						'This Month': [moment().startOf('month'), moment().endOf('month')],
						'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
					},
					eventHandlers: {
						'apply.daterangepicker': function(ev, picker) {
							$scope.viewmodel.dateFilter = {
								startDate: picker.startDate,
								endDate: picker.endDate
							};
							$scope.actions.loadLeaderboard(true);
						},
						'cancel.daterangepicker': function(ev, picker) {
							$scope.viewmodel.dateFilter = {
								startDate: null,
								endDate: null
							};
							$scope.actions.loadLeaderboard(true);
						}
					}
				}
            };

            $scope.viewmodel = {
				dateFilter: {
					startDate: null,
					endDate: null
				}
            };

            $scope.initialize = function () {
				gameService.getGames().then(function(games) {
					// Set global game_id
					localStorageService.set('game_id', localStorageService.get('game_id') || games[0].game_id);
					$scope.actions.loadLeaderboard();
				});
            };

            $scope.actions = {
				loadLeaderboard: function() {
					$scope.loading = true;

					$scope.viewmodel.players = [];

					statsService.getLeaderboard('highest', 1000, $scope.viewmodel.dateFilter.startDate, $scope.viewmodel.dateFilter.endDate).then(function(response) {
						$scope.viewmodel.players = response;
						$scope.loading = false;
					});
				},
				exportPlayerStats: function() {
					var reportData = [], i = 0;

					reportData.push(['Rank', 'Player', 'High Score']);

					for (i = 0; i < $scope.viewmodel.players.length; i++) {
						reportData.push([
							$scope.viewmodel.players[i].rank,
							$scope.viewmodel.players[i].display_name,
							$scope.viewmodel.players[i].high_score
						]);
					}

					return reportData;
				},
                createAlert: function (message, alertType) {
                    $scope.data.alerts = [];
                    $scope.data.alerts.push({ msg: message, type: alertType });
                },
            };

			$scope.$on('refresh', function(e, msg) {
				$scope.initialize();
			});

            $scope.initialize();
        }
    ]);