app.filter('unsafe', function($sce) {
	return function(val) {
		return $sce.trustAsHtml(val);
	};
})
.filter('trustUrl', ['$sce', function ($sce) {
	return function (recordingUrl) {
		return $sce.trustAsResourceUrl(recordingUrl);
	};
}])
.filter('formatDuration', function () {
    return function (input) {
        var totalHours, totalMinutes, totalSeconds, hours, minutes, seconds, result = '';

        totalSeconds = input / 1000;
        totalMinutes = totalSeconds / 60;
        totalHours = totalMinutes / 60;

        seconds = Math.floor(totalSeconds) % 60;
        minutes = Math.floor(totalMinutes) % 60;
        hours = Math.floor(totalHours) % 60;

        if (hours !== 0) {
            result += hours + ':';

            if (minutes.toString().length == 1) {
                minutes = '0' + minutes;
            }
        }

        result += minutes + ':';

        if (seconds.toString().length == 1) {
            seconds = '0' + seconds;
        }

        result += seconds;

        return result;
    };
})
.filter('percentage', ['$filter', function ($filter) {
	return function (input, decimals) {
		return $filter('number')(input * 100, decimals) + '%';
	};
}])
.filter('parseDate', function () {
	return function (date) {
		return Date.parse(date);
	};
})
.filter('userType', function () {
	return function (input) {
		switch (input) {
            case 'super_admin':
				return 'Super Admin';
            case 'admin':
				return 'Admin';
            case 'user':
				return 'User';
            case 'player':
				return 'Player';
            case 'team':
				return 'Team';
            default:
				return input;
        }
	};
})
.filter('titleCase', function () {
    return function (input) {
        input = input || '';
        return input.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };
})
.filter('sumProperty', function () {
    return function (data, key) {
        if (angular.isUndefined(data) || angular.isUndefined(key)) {
			return 0;
		}

        var sum = 0;

        angular.forEach(data, function(value) {
            sum = sum + parseInt(value[key], 10);
        });

        return sum;
    }
});