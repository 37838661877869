app.directive('affix', ['$window', '$document', function ($window, $document) {
	return {
		scope: {
			offsetTop: '@',
			offsetBottom: '@'
		},
		link: function (scope, element, attrs) {
			var win = angular.element($window),
				affixed;

			// Obviously, whenever a scroll occurs, we need to check and possibly
			// adjust the position of the affixed element.
			win.on('scroll', checkPosition);

			// Less obviously, when a link is clicked (in theory changing the current
			// scroll position), we need to check and possibly adjsut the position. We,
			// however, can't do this instantly as the page may not be in the right
			// position yet.
			win.on('click', function () {
				setTimeout(checkPosition, 1);
			});

			// This is mostly a direct port of the jQuery plugin. It checks to see if
			// we need to adjust the position of the affixed element and, if so, does
			// so.
			function checkPosition() {
				var scrollHeight = $document.prop('height'),
					scrollTop = win.prop('pageYOffset'),
					reset = 'affix affix-top affix-bottom',
					affix;

				// Calculate the affix state. This is either `top` indicating we are at
				// the top and do not need to affix anything or `false` to indicate that
				// the affixing should take place.
				// TODO: support the `offset` attribute as a function
				// TODO: support affixing to bottom
				if (scrollTop <= scope.offsetTop) {
					affix = 'top';
				} else {
					affix = false;
				}

				// If scrolling hasn't changed the satus of our affixing, why do aything
				// else? Instead of repeating what's already been done, we just return here.
				if (affixed === affix) return;

				// Update the affix status to whatever we just calculated.
				affixed = affix;

				// First, we remove any applicable classes and then add both the `affix`
				// class as well as whatever specialized class we need, if any.
				element.removeClass(reset).addClass('affix' + (affix ? '-' + affix : ''));
			}
		}
	};
}]);