angular.module('tgaAdmin')
    .controller('RegisterController', ['$scope', '$state', 'userService', 'settingsService', 'stripe',
		function ($scope, $state, userService, settingsService, stripe) {
			if(userService.isLoggedIn()) {
				$state.go('home');
			}

			$scope.data = {
				alerts: [],
				logo: '',
				defaultLogo: 'assets/images/tga-logo.png',
				step: 1,
				percent: 33.33,
				progressTitles: ['Create Account', 'Select Products', 'Payment'],
				products: [{
					id: 'game.jump',
					name: 'Jump Game',
					price: 599
				}, {
					id: 'game.match',
					name: 'Match Game',
					price: 599
				}]
			};

			$scope.viewmodel = {
				first_name: null,
				last_name: null,
				email: null,
				password: null,
				confirm_password: null,
				domain: null,
				products: [],
				card: {
					number: null,
					expiry: null,
					cvc: null
				}
			};

			$scope.initialize = function () {
				settingsService.getSettings().then(function(settings) {
					if(settings.logo) {
						$scope.data.logo = settings.uploads_url + '/' + settings.logo;
					} else {
						$scope.data.logo = $scope.data.defaultLogo;
					}
				});
			};

			$scope.actions = {
				accountForm: function () {
					if($scope.accountForm.$invalid) {
						return;
					}

					$scope.data.step = 2;
					$scope.data.percent = 66.66;
				},
				productForm: function () {
					if($scope.productForm.$invalid) {
						return;
					}

					$scope.data.step = 3;
					$scope.data.percent = 100;
				},
				paymentForm: function () {
					if($scope.paymentForm.$invalid) {
						return;
					}

					$scope.loading = true;

					var card = {
						number: $scope.viewmodel.card.number,
						exp_month: $scope.viewmodel.card.expiry.month,
						exp_year: $scope.viewmodel.card.expiry.year,
						cvc: $scope.viewmodel.card.cvc
					}

					stripe.card.createToken(card).then(function(response) {
						$scope.viewmodel.card = null;
						$scope.viewmodel.token = response.id;

						userService.registerUser($scope.viewmodel).then(function(user) {
							$state.go('home');
						}, function(err) {
							$scope.loading = false;
							$scope.actions.createAlert(err.message, 'danger');
						});
					}).catch(function (err) {
						$scope.loading = false;
						$scope.actions.createAlert(err.message, 'danger');
					});
				},
				checkEmail: function() {
					$scope.actions.clearAlerts();

					if(!$scope.viewmodel.email) {
						$scope.invalidEmail = null;
						return;
					}

					userService.checkEmail($scope.viewmodel.email).then(function(data) {
						$scope.invalidEmail = null;
						$scope.accountForm.email.$setValidity('unavailable', true);
					}, function (err) {
						$scope.invalidEmail = true;
						$scope.accountForm.email.$setValidity('unavailable', false);
					});
				},
				checkDomain: function() {
					$scope.actions.clearAlerts();

					if(!$scope.viewmodel.domain) {
						$scope.domain = null;
						return;
					}

					$scope.checkingDomain = true;

					settingsService.checkDomain($scope.viewmodel.domain).then(function(domain) {
						$scope.domain = domain;

						if(domain.available === false) {
							$scope.domain.message = 'Domain is unavailable.';
							$scope.accountForm.domain.$setValidity('required', false);
						} else {
							$scope.domain.message = 'Domain is available!';
						}

						$scope.checkingDomain = false;
					}, function (err) {
						$scope.domain = {
							available: false
						}

						if(err.message) {
							$scope.domain.message = err.message;
						}

						$scope.accountForm.domain.$setValidity('required', false);

						$scope.checkingDomain = false;
					});
				},
				toggleProduct: function(product) {
					var index = $scope.viewmodel.products.indexOf(product);

					if (index > -1) {
						product.checked = false;
						$scope.viewmodel.products.splice(index, 1);
					} else {
						product.checked = true;
						$scope.viewmodel.products.push(product);
					}
				},
				back: function (step) {
					$scope.data.step = step;
					$scope.data.percent = (step / 3) * 100;
				},
				createAlert: function (message, alertType) {
					$scope.data.alerts = [];
					$scope.data.alerts.push({ msg: message, type: alertType });
				},
				clearAlerts: function() {
					$scope.data.alerts = [];
				}
			};

			$scope.initialize();
		}
    ]);