angular.module('tgaAdmin').factory('cacheInterceptor', function () {
    return {
        request: function (config) {
            if (config.method == 'GET' && config.url.indexOf('uib') === -1) {
				var version = 9;

				if (!config.params) {
					config.params = {};
				}

				if (!config.params.v) {
					config.params.v = version;
				}
            }
            return config;
        }
    };
});

app.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('cacheInterceptor');
}]);