angular.module('tgaAdmin')
    .service('userService', ['$http', 'authService', 'localStorageService', 'envService',
        function ($http, authService, localStorageService, envService) {

            var serverAddress = envService.read('serverAddress');

            var storageKey = 'user';

            var user = null;

            return ({
				isLoggedIn: isLoggedIn,
                getUser: getUser,
                getUsers: getUsers,
                getUserData: getUserData,
                checkUserType: checkUserType,
                setUser: setUser,
                addUser: addUser,
                updateUser: updateUser,
                login: login,
                logout: logout,
				checkEmail: checkEmail,
				registerUser: registerUser,
				addPaymentSource: addPaymentSource
            });

			function isLoggedIn() {
				return authService.getAuthentication().isAuth;
			}

            function getUser(userId) {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'user/' + userId
                }).then(
                    function (response) {
                        return response.data;
                    },
                    function (error) {
                        throw error.data;
                    }
                );
            }

            function getUsers() {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'user'
                }).then(
                    function (response) {
                        return response.data;
                    },
                    function (error) {
                        throw error.data;
                    }
                );
            }

            function getUserData() {
                if (!user) {
                    user = localStorageService.get(storageKey);
                }

                return user;
            }

            function checkUserType(userTypes) {
				return userTypes.indexOf(getUserData()['user_type']) !== -1;
            }

            function setUser(newUser) {
                user = newUser;
                localStorageService.set(storageKey, user);
            }

            function addUser(data) {
                return $http({
                    method: 'POST',
                    url: serverAddress + 'user',
                    data: data
                }).then(
                    function (response) {
                        return response.data;
                    },
                    function (error) {
                        throw error.data;
                    }
                );
            }

            function updateUser(data, currentUser) {
                return $http({
                    method: 'PUT',
                    url: serverAddress + 'user',
                    data: data
                }).then(
                    function (response) {
						if(currentUser) {
							setUser(response.data);
						}

                        return response.data;
                    },
                    function (error) {
                        throw error.data;
                    }
                );
            }

            function login(loginData) {
                var authentication = authService.clearAuthentication();

                return $http({
                    method: 'POST',
                    url: serverAddress + 'user/login',
                    headers: { 'Content-Type': 'application/json' },
                    data: {
                        email: loginData.username,
                        password: loginData.password
                    }
                }).then(
                    function (response) {
                        authentication.isAuth = true;
                        authentication.username = loginData.username;
                        authentication.token = response.data.key;
                        authentication.userId = response.data.id;

                        authService.setAuthentication(authentication, loginData.remember);

                        return getUser(authentication.userId).then(function(currentUser) {
							setUser(currentUser);
							return currentUser;
						});
                    },
                    function (error) {
                        if(!serverAddress) {
                            setUser(true);
                            return true;
                        }

                        logout();
                        throw error.data;
                    }
                );
            }

            function logout() {
                authService.clearAuthentication();
                setUser(null);
            }

			function checkEmail(email) {
                return $http({
                    method: 'POST',
                    url: serverAddress + 'user/check',
                    data: {
						email: email
					}
                }).then(
                    function (response) {
                        return response.data;
                    },
                    function (error) {
                        throw error.data;
                    }
                );
            }

			function registerUser(data) {
				var authentication = authService.clearAuthentication();

                return $http({
                    method: 'POST',
                    url: serverAddress + 'user/register',
                    data: data
                }).then(
                    function (response) {
						authentication.isAuth = true;
                        authentication.username = data.email;
                        authentication.token = response.data.key;
                        authentication.userId = response.data.id;

                        authService.setAuthentication(authentication, true);

                        return getUser(authentication.userId).then(function(currentUser) {
							setUser(currentUser);
							return currentUser;
						});
                    },
                    function (error) {
                        throw error.data;
                    }
                );
            }

			function addPaymentSource(token) {
                return $http({
                    method: 'POST',
                    url: serverAddress + 'user/payment',
                    data: {
						token: token
					}
                }).then(
                    function (response) {
                        return response.data;
                    },
                    function (error) {
                        throw error.data;
                    }
                );
            }

        }]);