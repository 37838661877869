angular.module('tgaAdmin')
    .service('settingsService', ['$http', 'envService',
        function ($http, envService) {

            var serverAddress = envService.read('serverAddress');
			var settings = null;

            return ({
                getSettings: getSettings,
                updateSettings: updateSettings,
				checkDomain: checkDomain
            });

			function getSettings() {
				if(!settings) {
					settings = $http({
						method: 'GET',
						url: serverAddress + 'settings'
					}).then(
						function (response) {
							return response.data;
						},
						function (error) {
							throw error.data;
						}
					);
				}

				return settings;
            }

			function updateSettings(data) {
				settings = $http({
					method: 'PUT',
					url: serverAddress + 'settings',
					data: {
						settings: data
					}
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);

				return settings;
            }

			function checkDomain(domain) {
				return $http({
					method: 'POST',
					url: serverAddress + 'domain/check',
					data: {
						domain: domain
					}
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

        }]);