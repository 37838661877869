angular.module('tgaAdmin')
    .factory('authInterceptor', ['authService', 'envService', function (authService, envService) {
        var serverAddress = envService.read('serverAddress');
        var authInjector = {
            request: function (config) {
                var auth = authService.getAuthentication();
                if (auth && auth.isAuth && (config.url.substring(0, serverAddress.length) === serverAddress)) {
                    config.headers['X-TGA-KEY'] = auth.token;
                }
                return config;
            }
        };
        return authInjector;
    }]);

app.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('authInterceptor');
}]);