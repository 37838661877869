angular.module('tgaAdmin')
    .service('assetService', ['$http', 'envService',
        function ($http, envService) {

            var serverAddress = envService.read('serverAddress');
			var assets = null;

            return ({
                getAsset: getAsset,
                getAssets: getAssets,
                addAsset: addAsset,
                updateAsset: updateAsset,
                deleteAsset: deleteAsset,
                getBackgrounds: getBackgrounds,
                getCharacters: getCharacters,
                getTracks: getTracks,
                getThemes: getThemes
            });

			function getAsset(assetId) {
				return $http({
					method: 'GET',
					url: serverAddress + 'asset/' + assetId
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function getAssets(assetType) {
				if(assetType) {
					return $http({
						method: 'GET',
						url: serverAddress + 'asset',
						params: {
							asset_type: assetType
						}
					}).then(
						function (response) {
							return response.data;
						},
						function (error) {
							throw error.data;
						}
					);
				}

				if(!assets) {
					assets = $http({
						method: 'GET',
						url: serverAddress + 'asset'
					}).then(
						function (response) {
							return response.data;
						},
						function (error) {
							throw error.data;
						}
					);
				}

				return assets;
            }

			function addAsset(data) {
				// Clear assets cache
				assets = null;

				return $http({
					method: 'POST',
					url: serverAddress + 'asset',
					data: data
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function updateAsset(data) {
				// Clear assets cache
				assets = null;

				return $http({
					method: 'PUT',
					url: serverAddress + 'asset',
					data: data
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function deleteAsset(assetId) {
				// Clear assets cache
				assets = null;

				return $http({
					method: 'DELETE',
					url: serverAddress + 'asset/' + assetId
				}).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
				);
            }

			function getBackgrounds() {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'asset/background'
                }).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
                );
            }

			function getCharacters() {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'asset/character'
                }).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
                );
            }

			function getTracks(gameType) {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'asset/track',
					params: {
						game_type: gameType
					}
                }).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
                );
            }

			function getThemes(gameType) {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'asset/theme',
					params: {
						game_type: gameType
					}
                }).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
                );
            }

        }]);