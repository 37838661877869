angular.module('tgaAdmin')
    .controller('HeaderController', ['$scope', '$state', '$window', '$filter', 'userService', 'gameService', 'localStorageService',
        function ($scope, $state, $window, $filter, userService, gameService, localStorageService) {
			$scope.$state = $state;
			$scope.pageTitle = $scope.$parent.title || $state.current.data.title;

			$scope.$watch('$parent.title', function() {
				if($scope.$parent.title !== undefined) {
					$scope.pageTitle = $scope.$parent.title;
				}
			});

			$scope.showBack = $scope.$parent.showBack;

			$scope.data = {
				games: []
			}

			$scope.viewmodel = {
				gameId: null
			}

			$scope.initialize = function() {
				$('#aside').modal('hide');
				$('body').removeClass('modal-open').css('padding-right', 0);
				$('.modal-backdrop').remove();

				gameService.getGames().then(function(games) {
					$scope.data.games = games;

					if($scope.data.games.length) {
						$scope.viewmodel.gameId = localStorageService.get('game_id');

						if(!$filter('filter')($scope.data.games, {game_id: $scope.viewmodel.gameId}, true).length) {
							$scope.viewmodel.gameId = games[0].game_id;
						}

						localStorageService.set('game_id', $scope.viewmodel.gameId);
					}
				});
			}

			$scope.actions = {
				toggleNav: function() {
					$('#aside').modal('show');
				},
				changeGame: function() {
					localStorageService.set('game_id', $scope.viewmodel.gameId);
					$scope.$emit('refresh', true);
				},
				logout: function() {
					userService.logout();
					$state.go('login', {'reload': true});
				},
				back: function() {
					$window.history.back();
				}
            };

			$scope.initialize();
        }
    ]);