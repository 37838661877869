angular.module('tgaAdmin')
    .service('statsService', ['$http', 'envService', 'localStorageService',
        function ($http, envService, localStorageService) {

            var serverAddress = envService.read('serverAddress');

            return ({
                getLeaderboard: getLeaderboard,
                getHighScore: getHighScore,
                getQuestions: getQuestions,
                getQuestionStats: getQuestionStats,
                getSessionStats: getSessionStats,
            });

            function getLeaderboard(best, limit, startDate, endDate) {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'stats/leaderboard',
                    params: {
                        game_id: localStorageService.get('game_id'),
                        best: best,
                        limit: limit,
						start_date: startDate,
						end_date: endDate
                    }
                }).then(
                    function (response) {
                        return response.data;
                    },
                    function (error) {
                        throw error.data;
                    }
                );
            }

			function getHighScore(best, startDate, endDate) {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'stats/highscore',
                    params: {
                        game_id: localStorageService.get('game_id'),
                        best: best,
						start_date: startDate,
						end_date: endDate
                    }
                }).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
                );
            }

			function getSessionStats(startDate, endDate) {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'stats/session',
                    params: {
                        game_id: localStorageService.get('game_id'),
						start_date: startDate,
						end_date: endDate
                    }
                }).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
                );
            }

			function getQuestions() {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'question',
                    params: {
                        game_id: localStorageService.get('game_id')
                    }
                }).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
                );
            }

			function getQuestionStats(startDate, endDate) {
                return $http({
                    method: 'GET',
                    url: serverAddress + 'question/stats',
                    params: {
                        game_id: localStorageService.get('game_id'),
						start_date: startDate,
						end_date: endDate
                    }
                }).then(
					function (response) {
						return response.data;
					},
					function (error) {
						throw error.data;
					}
                );
            }

        }]);